<template>
  <div class="search">
    <h1>This is an search page</h1>
  </div>
</template>
<script>
export default {
    name:'search'
}
</script>
<style lang="less" scoped>

</style>